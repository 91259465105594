import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import ScrollToTop from "../components/scrollToTop";

const Home = React.lazy(() => import("./home"));
const RealestateInformation = React.lazy(() =>
  import("../components/realestateInformation")
);
const AboutUs = React.lazy(() => import("./aboutUs"));
const PrivacyPolicy = React.lazy(() => import("./privacyPolicy"));
const SingularRealtyHighlights = React.lazy(() =>
  import("./singularRealtyHighlights")
);
const RealestateHighlights = React.lazy(() => import("./realestateHighlights"));
const RealestateCategory = React.lazy(() => import("./realestateCategory"));
const RealestateSearchResults = React.lazy(() =>
  import("../components/realestateSearchResults")
);

export const Root = () => {
  return (
    <Router>
      <ScrollToTop />
      <Suspense>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/imoveis/:id" element={<RealestateInformation />} />
          <Route path="/sobre-nos" element={<AboutUs />} />
          <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
          <Route
            path="/destaques-singular-realty"
            element={<SingularRealtyHighlights />}
          />
          <Route
            path="/oportunidades-especiais"
            element={<RealestateHighlights />}
          />
          <Route
            path="/imoveis/categoria/:name"
            element={<RealestateCategory />}
          />
          <Route path="/buscar-imoveis" element={<RealestateSearchResults />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Root;
